/* eslint-disable react-hooks/exhaustive-deps */
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
// theme
import { hideScroll } from 'src/theme/css';
// hooks
// components
import { NavSectionMini } from 'src/components/nav-section';
//
import LogoMini from 'src/components/logo/LogoMini';
import { Link } from '@mui/material';
import { useCompany } from 'src/api-hooks/useCompany';
import { useEffect } from 'react';
import { NAV } from '../config-layout';
import { useNavData } from './config-navigation';
import { NavToggleButton } from '../_common';

// ----------------------------------------------------------------------

export default function NavMini() {
  const user = JSON.parse(sessionStorage.getItem('user'));
  const { getAllCompanies, companyData } = useCompany();
  useEffect(() => {
    getAllCompanies();
  }, []);

  const navData = useNavData();

  return (
    <Box
      component="nav"
      sx={{
        backgroundColor: '#fff',
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_MINI },
      }}
    >
      <NavToggleButton
        sx={{
          top: 22,
          left: NAV.W_MINI - 12,
        }}
      />

      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_MINI * 1,
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScroll.x,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          mt={3}
          mb={3}
          spacing={{ xs: 0.5, sm: 1 }}
        >
          <Link href="/">
            <img src={companyData?.data[0]?.companyImage} alt="company-logo" width={90} />
          </Link>
        </Stack>
        <NavSectionMini
          data={navData}
          config={{
            currentRole: user?.role || 'admin',
          }}
        />
        <Box sx={{ flexGrow: 1 }} />
        <Link
          href="/"
          sx={{ marginTop: 2, marginBottom: 2, marginLeft: 'auto', marginRight: 'auto' }}
        >
          <LogoMini size={80} />
        </Link>
      </Stack>
    </Box>
  );
}
