/* eslint-disable no-case-declarations */
/* eslint-disable no-unreachable */
import { SET_VIDEOWATCHED, SET_ANSWER, SET_SURVEYEND, SET_HIDETIPS } from './types';

const initialState = {
  answers: {},
  videoWatched: false,
  surveyEnd: false,
  hideTips: false,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_ANSWER:
      const { questionIndex, answerIndex, answerText, answerPoint } = payload;
      const updatedAnswers = {
        ...state.answers,
        [questionIndex]: {
          answerIndex,
          answerText,
          answerPoint,
        },
      };
      return { ...state, answers: updatedAnswers };

    case SET_VIDEOWATCHED:
      return { ...state, videoWatched: action.payload };

    case SET_SURVEYEND:
      return { ...state, surveyEnd: action.payload };
    case SET_HIDETIPS:
      return { ...state, hideTips: action.payload };
    default:
      return state;
  }
};

export default reducer;
