import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/dashboard/welcome'));
const WelcomePage = lazy(() => import('src/pages/dashboard/welcome'));
const PageSurvey = lazy(() => import('src/pages/dashboard/survey'));
const PageSprint = lazy(() => import('src/pages/dashboard/create-adoption-sprint'));
const PageSprintEdit = lazy(() => import('src/pages/dashboard/edit-adoption-sprint'));
const PageSprintResults = lazy(() => import('src/pages/dashboard/sprint-results'));
const PagePreviousSprints = lazy(() => import('src/pages/dashboard/access-previous-sprint'));
const PageSettings = lazy(() => import('src/pages/dashboard/settings'));
const PageHelp = lazy(() => import('src/pages/dashboard/help/help'));
const PageHelpQuestion = lazy(() => import('src/pages/dashboard/help/helpQuestion'));
const PageHelpAnswer = lazy(() => import('src/pages/dashboard/help/helpAnswer'));
const PageVerify = lazy(() => import('src/pages/auth/jwt/verify'));
// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'home',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'home', element: <WelcomePage /> },
      { path: 'survey', element: <PageSurvey /> },
      { path: 'create-adoption-sprint', element: <PageSprint /> },
      { path: 'edit-adoption-sprint', element: <PageSprintEdit /> },
      { path: 'sprint-results', element: <PageSprintResults /> },
      { path: 'access-previous-sprints', element: <PagePreviousSprints /> },
      { path: 'verify', element: <PageVerify /> },
      { path: 'settings', element: <PageSettings /> },
      {
        path: 'help',
        children: [
          { element: <PageHelp />, index: true },
          { path: 'question', element: <PageHelpQuestion /> },
          { path: 'answer', element: <PageHelpAnswer /> },
        ],
      },
    ],
  },
];
