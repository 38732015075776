/* eslint-disable consistent-return */
import { useState } from 'react';
import axios from 'axios';

export const useTrackAdoption = () => {
  const [tenKeyDimensionData, setTenKeyDimensionData] = useState(null);
  const [companyTotalData, setCompanyTotalData] = useState(null);
  const [allSprintsData, setAllSprintsData] = useState(null);
  const [allSprintsDataPerDimension, setAllSprintsDataPerDimension] = useState(null);
  const [valueCreationbyDepartmentsData, setValueCreationbyDepartmentsData] = useState(null);
  const [spaData, setSpaData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const authToken = sessionStorage.getItem('accessToken');

  const createTenKeyDimensions = async (
    sprintId,
    companyName,
    dimensionName,
    dimensionPoint,
    dimensionBestPractice,
    dimensionPeerGroup,
    dimensionTopQuintile,
    valueCreationbyDepartment
  ) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API}/track-adoption/createTenKeyDimensions`,
        {
          sprintId,
          companyName,
          dimensionName,
          dimensionPoint,
          dimensionBestPractice,
          dimensionPeerGroup,
          dimensionTopQuintile,
          valueCreationbyDepartment,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setTenKeyDimensionData(response.data.data);
      setError(null);
      return response.data;
    } catch (err) {
      setError(err);
      setTenKeyDimensionData(null);
      return err;
    } finally {
      setLoading(false);
    }
  };

  const getTenKeyDimensions = async (sprintId, companyName) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API}/track-adoption/getTenKeyDimensions`,
        {
          sprintId,
          companyName,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setTenKeyDimensionData(response.data.data);
      setError(null);
      return response.data.data;
    } catch (err) {
      setError(err);
      setTenKeyDimensionData(null);
    } finally {
      setLoading(false);
    }
  };
  const getCompanyTotal = async (sprintId, companyName) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API}/track-adoption/getCompanyTotal`,
        {
          sprintId,
          companyName,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setCompanyTotalData(response.data.data);
      setError(null);
    } catch (err) {
      setError(err);
      setCompanyTotalData(null);
    } finally {
      setLoading(false);
    }
  };
  const getSPA = async (sprintId) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API}/track-adoption/getSPA`,
        {
          sprintId,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setSpaData(response.data.data);
      setError(null);
    } catch (err) {
      setError(err);
      setSpaData(null);
    } finally {
      setLoading(false);
    }
  };
  const createCompanyTotal = async (sprintId, companyName, companyPoint) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API}/track-adoption/createCompanyTotal`,
        {
          sprintId,
          companyName,
          companyPoint,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setCompanyTotalData(response.data.data);
      setError(null);
      return response.data;
    } catch (err) {
      setError(err);
      setCompanyTotalData(null);
    } finally {
      setLoading(false);
    }
  };
  const createAllSprintsData = async (sprintId, sprintName, companyName, companyPoint) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API}/track-adoption/createAllSprints`,
        {
          sprintId,
          sprintName,
          companyName,
          companyPoint,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setAllSprintsData(response.data.data);
      setError(null);
      return response.data;
    } catch (err) {
      setError(err);
      setAllSprintsData(null);
      return err;
    } finally {
      setLoading(false);
    }
  };
  const createAllSprintsDataPerDimension = async (
    dimensionName,
    sprintName,
    companyName,
    sprintPoint
  ) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API}/track-adoption/createAllSprintDataPerDimensions`,
        {
          dimensionName,
          sprintName,
          companyName,
          sprintPoint,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setAllSprintsDataPerDimension(response.data.data);
      setError(null);
      return response.data.data;
    } catch (err) {
      setError(err);
      setAllSprintsDataPerDimension(null);
      return err;
    } finally {
      setLoading(false);
    }
  };
  const getAllSprintsDataPerDimension = async (companyName) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API}/track-adoption/getAllSprintDataPerDimensions`,
        {
          companyName,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setAllSprintsDataPerDimension(response.data.data);
      setError(null);
      return response.data.data;
    } catch (err) {
      setError(err);
      setAllSprintsDataPerDimension(null);
      return err;
    } finally {
      setLoading(false);
    }
  };
  const getAllSprintsData = async (companyName) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API}/track-adoption/getAllSprintsData`,
        {
          companyName,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setAllSprintsData(response.data.data);
      setError(null);
      return response.data.data;
    } catch (err) {
      setError(err);
      setAllSprintsData(null);
      return err;
    } finally {
      setLoading(false);
    }
  };
  const createValueCreationByDepartments = async (sprintId, department, dimensionPoint) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API}/track-adoption/createValueCreationByDepartments`,
        {
          sprintId,
          department,
          dimensionPoint,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setValueCreationbyDepartmentsData(response.data.data);
      setError(null);
      return response.data.data;
    } catch (err) {
      setError(err);
      setValueCreationbyDepartmentsData(null);
      return err;
    } finally {
      setLoading(false);
    }
  };
  const getValueCreationByDepartments = async (sprintId) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API}/track-adoption/getValueCreationByDepartments`,
        {
          sprintId,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setValueCreationbyDepartmentsData(response.data.data);
      setError(null);
      return response.data.data;
    } catch (err) {
      setError(err);
      setValueCreationbyDepartmentsData(null);
      return err;
    } finally {
      setLoading(false);
    }
  };

  return {
    createTenKeyDimensions,
    getCompanyTotal,
    createCompanyTotal,
    getTenKeyDimensions,
    createAllSprintsDataPerDimension,
    createAllSprintsData,
    getSPA,
    getAllSprintsData,
    getAllSprintsDataPerDimension,
    createValueCreationByDepartments,
    getValueCreationByDepartments,
    spaData,
    tenKeyDimensionData,
    allSprintsData,
    valueCreationbyDepartmentsData,
    allSprintsDataPerDimension,
    companyTotalData,
    loading,
    error,
  };
};
