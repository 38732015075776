/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

import OtpInput from 'react-otp-input';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// routes
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
// components
import Iconify from 'src/components/iconify';

import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Snackbar,
  TextField,
} from '@mui/material';
import SuccessIcon from 'src/assets/icons/success';
import { useRouter, useSearchParams } from 'src/routes/hooks';
import { PATH_AFTER_LOGIN } from 'src/config-global';
import { useAuthentication } from 'src/api-hooks/useAuthentication';
import CloseIcon from '@mui/icons-material/Close';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';

// ----------------------------------------------------------------------

export default function ForgotPasswordView() {
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
  });

  const { forgotPassword, forgotPasswordChange, checkOTP } = useAuthentication();
  const [email, setEmail] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const [login, setLogin] = useState(false);
  const [errorOtp, setErrorOtp] = useState(false);
  const [successOTP, setSuccessOTP] = useState(false);
  const [passwordChangeSuccess, setPasswordChangeSuccess] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [countdown, setCountdown] = useState(5);
  const [otpSection, setOtpSection] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const router = useRouter();
  const searchParams = useSearchParams();

  const returnTo = searchParams.get('returnTo');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  useEffect(() => {
    let interval;

    if (passwordChangeSuccess && countdown > 0) {
      interval = setInterval(() => {
        setCountdown((prevCount) => prevCount - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [passwordChangeSuccess, countdown]);

  useEffect(() => {
    if (countdown === 0 && passwordChangeSuccess) {
      router.push(returnTo || PATH_AFTER_LOGIN);
    }
  }, [countdown, passwordChangeSuccess]);

  // useEffect(() => {
  //   if (otp === '111111') {
  //     setSuccessOTP(true);
  //     setErrorOtp(false);
  //   } else if (otp.length === 6 && otp !== '111111') {
  //     setSuccessOTP(false);
  //     setErrorOtp(true);
  //   }
  // }, [otp]);

  const handleSendRequestButton = () => {
    setOtpSection(true);
    if (email.length === 0) {
      setOpenAlert(true);
      setAlertMessage('Please enter your Email address');
    }
    forgotPassword(email).then((res) => {
      if (res && res.data.status === 'success') {
        setLogin(true);
      } else if (res === undefined) {
        setAlertMessage(
          'Your code has been sent to your e-mail address. You must wait 15 minutes to request the code again.'
        );
        setOpenAlert(true);
      }
    });
  };
  const handleConfirmButton = () => {
    if (otp.length === 6) {
      checkOTP(email, otp).then((otpResponse) => {
        if (otpResponse && otpResponse.data.status === 'success') {
          setSuccessOTP(true);
          setErrorOtp(false);
          setOtpSection(false);
        } else {
          setSuccessOTP(false);
          setErrorOtp(true);
        }
      });
    }
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleUpdatePassword = () => {
    if (password.length === 0) {
      setAlertMessage('Please enter a password');
      setOpenAlert(true);
    } else if (confirmPassword.length === 0) {
      setAlertMessage('Please confirm your password');
      setOpenAlert(true);
    } else if (password === confirmPassword) {
      forgotPasswordChange(email, password).then((updatePasswordResponse) => {
        if (updatePasswordResponse && updatePasswordResponse.data.status === 'success') {
          setPasswordChangeSuccess(true);
        }
      });
    } else {
      setAlertMessage('Your password does not match');
      setOpenAlert(true);
    }
  };

  const renderFormPassword = (
    <Stack spacing={2} sx={{ width: 350 }}>
      <FormControl variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => setPassword(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Password"
        />
      </FormControl>
      <FormControl variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => setConfirmPassword(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Confirm Password"
        />
      </FormControl>

      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        onClick={handleUpdatePassword}
      >
        Update Password
      </LoadingButton>
    </Stack>
  );
  const passwordChangeSuccessView = (
    <Stack alignItems="center" spacing={2}>
      <SuccessIcon />
      <Typography variant="h4">Password Updated.</Typography>
      <Typography variant="subtitle1">
        You are redirecting to sign in {countdown} seconds.
      </Typography>
      <Link
        component={RouterLink}
        href={paths.auth.jwt.login}
        color="inherit"
        variant="subtitle2"
        sx={{
          alignItems: 'center',
          display: 'inline-flex',
        }}
      >
        <Iconify icon="eva:arrow-ios-back-fill" width={16} />
        Return to sign in
      </Link>
    </Stack>
  );

  const renderForm = (
    <Stack spacing={3} alignItems="start">
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleClose}
        message={alertMessage}
        action={action}
      />
      {!login ? (
        <>
          <Typography variant="h4" textAlign="start">
            Forgot Password
          </Typography>
          <Typography variant="body2" textAlign="start" color="GrayText">
            Enter the email address associated with your account, and we will send you a link to
            reset your password.
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="h4">Request sent successfully!</Typography>
          <Typography variant="body2" textAlign="start" color="GrayText">
            We have sent a 6-digit confirmation email to your email. Please enter the code in below
            box to verify your email.
          </Typography>
        </>
      )}
      {!login && (
        <TextField label="Email address" fullWidth onChange={(e) => setEmail(e.target.value)} />
      )}

      {login ? (
        <>
          {otpSection && (
            <>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                inputType="number"
                onPaste={
                  (React.ClipboardEventHandler = (event) => {
                    const data = event.clipboardData.getData('text');
                    console.log(data);
                  })
                }
                inputStyle={{
                  width: 40,
                  height: 50,
                  borderColor: errorOtp && 'red',
                  marginLeft: 15,
                  borderRadius: 10,
                }}
                renderInput={(props) => <input style={{ boxShadow: 0 }} {...props} />}
              />
              <Button variant="contained" fullWidth onClick={handleConfirmButton}>
                Confirm
              </Button>
              {errorOtp && (
                <Typography variant="subtitle1" sx={{ fontSize: 14, color: '#C20002' }}>
                  The code seems to be incorrect, please check again.
                </Typography>
              )}
              {!successOTP && (
                <Box sx={{ display: 'flex', alignSelf: 'center' }}>
                  <Typography variant="subtitle1">Don’t have a code?</Typography>
                  <Link
                    color="green"
                    variant="subtitle1"
                    sx={{ marginLeft: 1, cursor: 'pointer' }}
                    onClick={() => {
                      handleSendRequestButton();
                      setOtp('');
                      setErrorOtp(false);
                    }}
                  >
                    Resend code
                  </Link>
                </Box>
              )}
            </>
          )}

          {successOTP && <Box>{renderFormPassword}</Box>}
        </>
      ) : (
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={handleSendRequestButton}
        >
          Send Request
        </LoadingButton>
      )}

      <Link
        component={RouterLink}
        href={paths.auth.jwt.login}
        color="inherit"
        variant="subtitle2"
        sx={{
          alignItems: 'center',
          display: 'inline-flex',
          alignSelf: 'center',
        }}
      >
        <Iconify icon="eva:arrow-ios-back-fill" width={16} />
        Return to sign in
      </Link>
    </Stack>
  );

  const renderHead = (
    <Stack spacing={1} sx={{ my: 5 }}>
      <Typography variant="h3">Forgot your password?</Typography>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        Enter the email address associated with your account, and we will send you a link to reset
        your password.
      </Typography>
    </Stack>
  );

  return passwordChangeSuccess ? passwordChangeSuccessView : renderHead && renderForm;
}
