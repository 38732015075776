/* eslint-disable react/jsx-no-constructed-context-values */
// src/contexts/UserUpdateContext.js
import React, { createContext, useContext, useState } from 'react';

const UserUpdateContext = createContext(null);

export const useUserUpdate = () => useContext(UserUpdateContext);

export const UserUpdateProvider = ({ children }) => {
  const [update, setUpdate] = useState(false);

  const triggerUpdate = () => {
    setUpdate(true);
  };

  const resetUpdate = () => {
    setUpdate(false);
  };

  return (
    <UserUpdateContext.Provider value={{ update, triggerUpdate, resetUpdate }}>
      {children}
    </UserUpdateContext.Provider>
  );
};
