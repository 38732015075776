import * as React from 'react';

const SuccessIcon = ({ size }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size || 56} height={size || 56} fill="none">
    <g filter="url(#a)">
      <path
        fill="url(#b)"
        fillRule="evenodd"
        d="M44 24c0 11.046-8.954 20-20 20S4 35.046 4 24 12.954 4 24 4s20 8.954 20 20Zm-21.052 8.105L35.194 19.75a2.803 2.803 0 0 0 0-3.934 2.726 2.726 0 0 0-1.95-.815c-.736 0-1.428.29-1.949.815l-10.297 10.39-4.292-4.331a2.725 2.725 0 0 0-1.95-.815c-.736 0-1.428.29-1.949.815A2.776 2.776 0 0 0 12 23.84c0 .743.287 1.441.807 1.967l6.242 6.297c.52.526 1.213.815 1.95.815.736 0 1.428-.29 1.949-.815Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <linearGradient id="b" x1={4} x2={44} y1={4} y2={44} gradientUnits="userSpaceOnUse">
        <stop stopColor="#5BE49B" />
        <stop offset={1} stopColor="#00A76F" />
      </linearGradient>
      <filter
        id="a"
        width={56}
        height={56}
        x={0}
        y={0}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={4} dy={4} />
        <feGaussianBlur stdDeviation={4} />
        <feColorMatrix values="0 0 0 0 0.568627 0 0 0 0 0.619608 0 0 0 0 0.670588 0 0 0 0.16 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_24_177726" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_24_177726" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={-1} dy={-1} />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.568627 0 0 0 0 0.619608 0 0 0 0 0.670588 0 0 0 0.48 0" />
        <feBlend in2="shape" result="effect2_innerShadow_24_177726" />
      </filter>
    </defs>
  </svg>
);
export default SuccessIcon;
