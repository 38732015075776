import PropTypes from 'prop-types';
// @mui
import Stack from '@mui/material/Stack';

import { Box } from '@mui/system';
import LogoSVG from 'src/components/logo/LogoSVG';

// ----------------------------------------------------------------------

export default function AuthClassicLayout({ children, image, title }) {
  const renderLogo = (
    <Box
      sx={{
        m: { xs: 5, md: 10 },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <LogoSVG size={200} />
    </Box>
  );

  const renderContent = (
    <Stack
      sx={{
        background: 'white',
        marginTop: -10,
        boxShadow: 6,

        borderRadius: 2,
        width: 1,
        mx: 'auto',
        maxWidth: 480,
        px: { xs: 2, md: 8 },
        py: { xs: 15, md: 10 },
      }}
    >
      {children}
    </Stack>
  );

  return (
    <Stack
      component="main"
      direction="column"
      sx={{
        background: '#ecf0ff',
        backgroundImage: "url('/assets/background/overlay_4.png')",

        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        minHeight: '100vh',
      }}
    >
      {renderLogo}

      {renderContent}
    </Stack>
  );
}

AuthClassicLayout.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string,
  title: PropTypes.string,
};
