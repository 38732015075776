import { useState } from 'react';
import axios from 'axios';

export const useCompany = () => {
  const [managerData, setManagerData] = useState(null);
  const [companyData, setCompanyData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const authToken = sessionStorage.getItem('accessToken');

  const getManagers = async (company) => {
    setLoading(true);
    try {
      if (authToken !== null) {
        const response = await axios.post(
          `${process.env.REACT_APP_HOST_API}/company/getManagers`,
          { company },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              'Content-Type': 'application/json',
            },
          }
        );

        setManagerData(response.data);
        setError(null);
      } else {
        alert('fdfdf');
      }
    } catch (err) {
      setError(err);
      setManagerData(null);
    } finally {
      setLoading(false);
    }
  };
  const createCompany = async (companyName, companyImage) => {
    setLoading(true);
    try {
      if (authToken !== null) {
        const response = await axios.get(
          `${process.env.REACT_APP_HOST_API}/company/createCompany`,
          { companyName, companyImage },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              'Content-Type': 'application/json',
            },
          }
        );
        setCompanyData(response.data);
        setError(null);
      } else {
        alert('fdfdf');
      }
    } catch (err) {
      setError(err);
      setCompanyData(null);
    } finally {
      setLoading(false);
    }
  };
  const getAllCompanies = async () => {
    setLoading(true);
    try {
      if (authToken !== null) {
        const response = await axios.get(
          `${process.env.REACT_APP_HOST_API}/company/getAllCompanies`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              'Content-Type': 'application/json',
            },
          }
        );
        setCompanyData(response.data);
        setError(null);
      } else {
        alert('fdfdf');
      }
    } catch (err) {
      setError(err);
      setCompanyData(null);
    } finally {
      setLoading(false);
    }
  };
  const addManager = async (jsonData) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API}/settings/getSettings`,
        jsonData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setCompanyData(response.data);
      setError(null);
    } catch (err) {
      setError(err);
      setCompanyData(null);
    } finally {
      setLoading(false);
    }
  };

  return {
    getManagers,
    createCompany,
    getAllCompanies,
    addManager,
    managerData,
    companyData,
    loading,
    error,
  };
};
