/* eslint-disable consistent-return */
import { useState } from 'react';
import axios from 'axios';

export const useRunningSprints = () => {
  const [runningSprintData, setRunningSprintData] = useState(null);
  const [sprintComplated, setSprintComplated] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const authToken = sessionStorage.getItem('accessToken');

  const getRunningSprints = async (sprintId, company) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API}/running-sprints/getAll?company=${company}&sprintId=${sprintId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setRunningSprintData(response.data.data);
      setError(null);
      return response.data.data;
    } catch (err) {
      setError(err);
      setRunningSprintData(null);
    } finally {
      setLoading(false);
    }
  };
  const addRunningSprint = async (
    sprintId,
    company,
    diffDepartments,
    selectedManagers,
    departments
  ) => {
    const dimensions = JSON.parse(sessionStorage.getItem('dimensions'));
    const user = JSON.parse(sessionStorage.getItem('user'));

    if (diffDepartments === 'yes') {
      const convertedRunningSprints = dimensions.map((dimension) => {
        const sprintObject = {
          sprintId,
          dimension: dimension.dimensionName,
          progress: 0,
          assigned: dimension.manager || `${user?.name} ${user?.surname}`,
          company,
        };

        if (dimension.dimensionName === 'Value Creation') {
          sprintObject.subDimensions = Object.keys(departments).map((department) => ({
            assigned: departments[department].assigned || `${user?.name} ${user?.surname}`,
            department,
            progress: 0,
          }));
        }

        return sprintObject;
      });

      console.log('Converted Running Sprints with Departments:', convertedRunningSprints);

      setLoading(true);
      try {
        if (sprintId !== null) {
          const response = await axios.post(
            `${process.env.REACT_APP_HOST_API}/running-sprints/create`,
            { convertedRunningSprints },
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'application/json',
              },
            }
          );
          setRunningSprintData(response.data);
          setError(null);
        }
      } catch (err) {
        setError(err);
        setRunningSprintData(null);
      } finally {
        setLoading(false);
      }
    } else {
      const convertedRunningSprints = dimensions.map((dimension) => ({
        sprintId,
        dimension: dimension.dimensionName,
        progress: 0,
        assigned: dimension.manager || `${user?.name} ${user?.surname}`,
        company,
      }));

      console.log('Converted Running Sprints without Departments:', convertedRunningSprints);

      setLoading(true);
      try {
        if (sprintId !== null) {
          const response = await axios.post(
            `${process.env.REACT_APP_HOST_API}/running-sprints/create`,
            { convertedRunningSprints },
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'application/json',
              },
            }
          );
          setRunningSprintData(response.data);
          setError(null);
        }
      } catch (err) {
        setError(err);
        setRunningSprintData(null);
      } finally {
        setLoading(false);
      }
    }
  };

  const updateRunningSprint = async (jsonData) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API}/running-sprints/update`,
        jsonData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setRunningSprintData(response.data);
      setError(null);
    } catch (err) {
      setError(err);
      setRunningSprintData(null);
    } finally {
      setLoading(false);
    }
  };
  const checkSprintComplated = async (sprintId) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API}/running-sprints/checkSprintComplate`,
        { sprintId },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setSprintComplated(response.data);
      setError(null);
    } catch (err) {
      setError(err);
      setSprintComplated(null);
    } finally {
      setLoading(false);
    }
  };
  const updateProgressRunningSprint = async (dimensionId, totalQuestions, answeredQuestion) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API}/running-sprints/updateProgress`,
        { dimensionId, totalQuestions, answeredQuestion },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setRunningSprintData(response.data.data);
      setError(null);
    } catch (err) {
      setError(err);
      setRunningSprintData(null);
    } finally {
      setLoading(false);
    }
  };
  const updateProgressRunningSprintSub = async (
    dimensionId,
    department,
    totalQuestions,
    answeredQuestion
  ) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API}/running-sprints/updateProgressSub`,
        { dimensionId, department, totalQuestions, answeredQuestion },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setRunningSprintData(response.data.data);
      setError(null);
    } catch (err) {
      setError(err);
      setRunningSprintData(null);
    } finally {
      setLoading(false);
    }
  };
  return {
    getRunningSprints,
    addRunningSprint,
    checkSprintComplated,
    updateProgressRunningSprint,
    updateProgressRunningSprintSub,
    updateRunningSprint,
    runningSprintData,
    sprintComplated,
    loading,
    error,
  };
};
