/* eslint-disable consistent-return */
import { useState } from 'react';
import axios from 'axios';

export const useAuthentication = () => {
  const [forgotPasswordData, setForgotPasswordData] = useState(null);
  const [checkOTPData, setCheckOTPData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const authToken = sessionStorage.getItem('accessToken');

  const forgotPassword = async (email) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API}/users/forgotPassword`,
        { email },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      setForgotPasswordData(response.data);
      setError(null);
      return response;
    } catch (err) {
      setError(err);
      setForgotPasswordData(null);
    } finally {
      setLoading(false);
    }
  };
  const forgotPasswordChange = async (email, password) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API}/users/forgotPasswordChange`,
        { email, password },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      setForgotPasswordData(response.data);
      setError(null);
      return response;
    } catch (err) {
      setError(err);
      setForgotPasswordData(null);
    } finally {
      setLoading(false);
    }
  };
  const checkOTP = async (email, otp) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API}/users/checkOTP`,
        { email, otp },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      setCheckOTPData(response.data);
      setError(null);
      return response;
    } catch (err) {
      setError(err);
      setCheckOTPData(null);
    } finally {
      setLoading(false);
    }
  };
  const verifyAccount = async (email, otp) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API}/users/verifyAccount`,
        { email, otp },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      setCheckOTPData(response.data);
      setError(null);
      return response;
    } catch (err) {
      setError(err);
      setCheckOTPData(null);
    } finally {
      setLoading(false);
    }
  };
  const loginWithToken = async (token) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API}/users/loginWithToken`,
        { token },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      setCheckOTPData(response.data);
      setError(null);
      return response;
    } catch (err) {
      setError(err);
      setCheckOTPData(null);
    } finally {
      setLoading(false);
    }
  };

  return {
    forgotPassword,
    forgotPasswordChange,
    checkOTP,
    verifyAccount,
    loginWithToken,
    checkOTPData,
    forgotPasswordData,
    loading,
    error,
  };
};
