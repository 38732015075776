// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/home',
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
      forgotPassword: `${ROOTS.AUTH}/jwt/forgot-password`,
    },
  },
  // DASHBOARD
  home: {
    root: ROOTS.DASHBOARD,
    verify: `${ROOTS.DASHBOARD}/verify`,
    survey: `${ROOTS.DASHBOARD}/survey`,
    sprint: `${ROOTS.DASHBOARD}/create-adoption-sprint`,
    sprintResult: `${ROOTS.DASHBOARD}/sprint-results`,
    previousSprint: `${ROOTS.DASHBOARD}/access-previous-sprints`,
    settings: `${ROOTS.DASHBOARD}/settings`,
    help: {
      help: `${ROOTS.DASHBOARD}/help`,
      question: `${ROOTS.DASHBOARD}/help/question`,
      answer: `${ROOTS.DASHBOARD}/help/answer`,
    },
  },
};
