/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import { m } from 'framer-motion';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// routes
import { useRouter } from 'src/routes/hooks';
// auth
import { useAuthContext } from 'src/auth/hooks';
// components
import { varHover } from 'src/components/animate';
import { useSnackbar } from 'src/components/snackbar';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import StarIcon from '@mui/icons-material/Star';
import SettingsIcon from '@mui/icons-material/Settings';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import PolicyIcon from '@mui/icons-material/Policy';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTrackAdoption } from 'src/api-hooks/useTrackAdoption';
import { useEffect, useState } from 'react';
import HelpIcon from 'src/assets/icons/help-icon';
import { useRunningSprints } from 'src/api-hooks/useRunningSprints';
// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: 'Settings',
    linkTo: '/home/settings',
    icon: <SettingsIcon />,
  },
  {
    label: 'Introduction & Help',
    linkTo: '/home/help',
    icon: <HelpIcon size={24} />,
  },
  {
    label: 'Privacy policy',
    linkTo: 'https://aibusinessschool.com/privacy-policy/',
    icon: <PolicyIcon />,
  },
];

const OPTIONSDIMENSIONSCOMPLATED = [
  {
    label: 'Settings',
    linkTo: '/home/settings',
    icon: <SettingsIcon />,
  },
  {
    label: 'Track Adoption Progress',
    linkTo: '/home/sprint-results',
    icon: <AnalyticsIcon />,
  },
  {
    label: 'Introduction & Help',
    linkTo: '/home/help',
    icon: <HelpIcon />,
  },
  {
    label: 'Privacy policy',
    linkTo: 'https://aibusinessschool.com/privacy-policy/',
    icon: <PolicyIcon />,
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const router = useRouter();
  const user = JSON.parse(sessionStorage.getItem('user'));
  const { logout } = useAuthContext();
  const [sprintId, setSprintId] = useState(sessionStorage.getItem('sprintId'));

  const { getTenKeyDimensions, tenKeyDimensionData } = useTrackAdoption();

  const { checkSprintComplated, sprintComplated } = useRunningSprints();

  useEffect(() => {
    checkSprintComplated(sprintId);

    const interval = setInterval(() => {
      const newSprintId = sessionStorage.getItem('sprintId');
      if (newSprintId !== sprintId) {
        setSprintId(newSprintId);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [sprintId]);

  useEffect(() => {
    getTenKeyDimensions(sprintId, user?.company);
  }, []);

  const { enqueueSnackbar } = useSnackbar();

  const popover = usePopover();

  const handleLogout = async () => {
    try {
      await logout();
      popover.onClose();
      router.replace('/');
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleClickItem = (path) => {
    if (path.startsWith('http://') || path.startsWith('https://')) {
      window.location.href = path;
    } else {
      router.push(path);
    }
  };

  return (
    <Box sx={{ marginRight: '3%' }}>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
        }}
      >
        <Avatar
          alt={user?.name}
          sx={{
            width: 36,
            height: 36,
            background: 'white',
            border: (theme) => `solid 1px grey`,
            boxShadow: 5,
            color: 'black',
          }}
        >
          {user?.name.charAt(0)}
          {user?.surname.charAt(0)}
        </Avatar>
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 250, p: 0 }}>
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.name} {user?.surname}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              marginTop: 1,
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <StarIcon sx={{ color: 'orange' }} />
            <Typography variant="body2" sx={{ color: 'text.secondary', marginLeft: 1 }} noWrap>
              {user?.role === 'superAdmin'
                ? 'Super Admin'
                : user?.role === 'sprintOwner'
                ? 'Sprint Owner'
                : 'Dimension Owner'}
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />
        {sprintComplated?.data !== 'Sprint complated' ? (
          <Stack sx={{ p: 1 }}>
            {OPTIONS.map((option) => (
              <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
                {option.icon}
                {option.label}
              </MenuItem>
            ))}
          </Stack>
        ) : (
          <Stack sx={{ p: 1 }}>
            {OPTIONSDIMENSIONSCOMPLATED.map((option) => (
              <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
                {option.icon}
                {option.label}
              </MenuItem>
            ))}
          </Stack>
        )}

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={handleLogout}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
        >
          <LogoutIcon />
          Log out
        </MenuItem>
      </CustomPopover>
    </Box>
  );
}
